import React from 'react'
import { Redirect } from 'react-router-dom'
import { Store } from 'Store'
import withTheme from 'hoc/withTheme'

import themes from './themes/__supportedThemes.js'
import Payment from 'components/Payment'

export default withTheme(themes)(({ location, match, theme }) => {
    const { state } = React.useContext(Store)

    if (!state.lead) {
        return <Redirect to={`/${match.params.url}${location.search}`} push />
    }

    return <Payment />
})
